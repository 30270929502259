/**
 * hae-isaa.js
 * -------------------
 *
 * - haku
 */

(function($) {

  $.fn.isa_hae = function() {

    var $container = $(this),
        $name_input = $(this).find('#company-search-name-query'),
        $name_btn = $(this).find('#company-search-name-button'),
        $location_input = $(this).find('#company-search-location-query'),
        $location_btn = $(this).find('#company-search-location-button'),
        $not_found = $(this).find('.company-search-not-found'),
        $results = $(this).find('.company-search-results'),
        $items = $(this).find('.company-item').not('.company-item-heading'), // HTML items
        items = isa_hae; // JSON items (printed by PHP)


    /**
     * React to search result
     */

    function react_to_result(search) {
      $items.addClass('hidden');
      $not_found.addClass('hidden');
      if(search.length) {
        $.each(search, function( index, item ) {
          $('#isa-company-' + item.id).removeClass('hidden');
        });
      } else {
        $not_found.removeClass('hidden');
      }
    }

    /**
     * Move to results on btn click
     */

    function go_to_restults() {
      $('html, body').animate({
          scrollTop: $results.offset().top
        }, 500);
    }

    $name_btn.click(function(e){
      e.preventDefault();
      go_to_restults();
    });

    $location_btn.click(function(e){
      e.preventDefault();
      go_to_restults();
    });

    $name_input.keypress(function(e) {
      if (e.which == 13) {
        go_to_restults();
      }
    });

    $location_input.keypress(function(e) {
      if (e.which == 13) {
        go_to_restults();
      }
    });


    /**
     * Search by name
     */
    $name_input.keyup(function(e) {
      if (e.which !== 13) {
        $location_input.val('');
        if($(this).val() !== '') {
          var value = $(this).val();
          value = value.toLowerCase();
          var search = _.filter(items, function(o) {
            var position = o.name.indexOf(value);
            if (position == -1) {
              return false;
            }
            return true;
          });

          react_to_result(search);

        } else {
          $items.removeClass('hidden');
        }
      }
    });

    /**
     * Search by location
     */
    $location_input.keyup(function(e) {
      if (e.which !== 13) {
        $name_input.val('');
        if($(this).val() !== '') {
          var value = $(this).val();
          value = value.toLowerCase();
          var search = _.filter(items, function(o) {
            var position = o.locations.indexOf(value);
            if (position == -1) {
              return false;
            }
            return true;
          });

          react_to_result(search);

        } else {
          $items.removeClass('hidden');
        }
      }
    });


  };

})(jQuery);
