/**
 * Aucor Navigation.js
 * -------------------
 *
 * Features:
 * - only adds classes, no show() or hide()
 * - timer for hover exit: better usability
 * - works with tabs: a11y
 * - desktop menu with touch: doubletap
 * - mobile menu with touch
 * - works at least with 3 levels (probably more)
 *
 */

(function($) {

  $.fn.aucor_side_navigation = function( options ) {

    // Default settings
    var defaults = {
      menu_toggle: "#menu-toggle",
      menu_items: []
    };

    var settings = $.extend( {}, defaults, options ),
        original_id = 0,
        $menu = $(this),
        $menu_container = $menu.find('#side-menu-container'),
        $menu_toggle = $(settings.menu_toggle), // hamburger icon
        menu_items = settings.menu_items;


    /**
     * Render initial menu by attr-id="123" from <body> tag
     */

    $(document).ready(function() {
      original_id = parseInt($('#side-menu').attr('attr-id'));
      render_menu_for_id(parseInt($('#side-menu').attr('attr-id')), 'to-right');
    });

    /**
     * Close menu on click to outside of menu
     */

    $('#content-dimmer').click(function() {
      $menu_toggle.trigger('click');
    });

    /**
     * Switch screens on click to <button class="screen-switcher">
     */

    $menu.on('click', '.screen-switcher', function() {
      var target_id = $(this).attr('attr-id');
      var direction = 'to-right';
      if($(this).hasClass('to-left')) {
        direction = 'to-left';
      }
      render_menu_for_id(parseInt(target_id), direction);
    });

    /**
     * Render prev/first component of navigation
     */

    function render_navigation(response) {
      var current = response.current;
      var parent = response.top;
      var html = '';
      if(current.parent > -1) {
        html += '<div class="screen-navigation">';

        if(parent.parent > 0) {
          html += '<button class="screen-switcher to-left prev" attr-id="' + parent.parent + '">' + $('#svg-arrow').html() + ' Takaisin</button>';
        }

        if(parent.parent > -1) {
          html += '<button class="screen-switcher to-left fast-forward" attr-id="0">' + $('#svg-fast-forward').html() + ' Päätasolle</button>';
        }

        html += '</div>';
      }

      return $(html);

    }


    /**
     * Render <a> component from item (with button to next level)
     */

    function get_link_rendered(item) {
      var output = '';
      output += '<a href="' + item.link + '">' + item.title + '</a>';
      if(has_children(item.id)) {
        output += '<button attr-id="' + item.id + '" class="screen-switcher to-right">' + $('#svg-plus').html() + '</button>';
      }
      return output;
    }

    /**
     * Render the <ul> component with items (children and siblings)
     */

    function render_ul_menu(response) {
      $ul = $('<ul class="menu"></ul>');
      for (var i = 0; i < response.items.length; i++) {
        var item = response.items[i];
        var $li = $('<li class="menu-item-' + item.id + '">' + get_link_rendered(item) + '</li>');
        $ul.append($li);
      }
      return $ul;
    }

    /**
     * Render menu for given page_id, direction to-right/to-left (is this parent or sub)
     */

    function render_menu_for_id(page_id, direction) {

      // maybe get fallback id for given page_id
      var item = get_item(page_id);
      if(has_children(page_id)) {
        var page_id = item.id;
      } else {
        var page_id = item.parent;
      }

      // figure out if this should return children or parents
      var response = get_relevant_items_for_id(page_id);

      // container
      var $html = $('<div class="screen side-menu-screen">');

      // get prev / beginning navigation
      $html.append(render_navigation(response));

      // Add "title"
      $html.append('<h2><a href="' + response.top.link + '">' + response.top.title + '</a></h2>');

      // Render <ul>
      var $ul = render_ul_menu(response);

      // Highlight current menu item
      $ul.find('.menu-item-' + original_id).addClass('current-menu-item');

      // Prepare <ul> to be appended
      $html.append($ul);

      // Animate and append
      $menu_container.addClass('disappear');
      $menu_container.addClass(direction);
      setTimeout(function(){
        $menu_container.html($html);
        $menu_container.removeClass('disappear');
        $menu_container.removeClass('to-left');
        $menu_container.removeClass('to-right');
      }, 200);

    }

    /**
     * Get relevant menu items for given id (children or siblings)
     */

    function get_relevant_items_for_id(page_id) {
      if(has_children(page_id)) {
        var items = {
          'type' : 'children',
          'top' : get_item(page_id),
          'current' : get_item(page_id),
          'items' : _.filter(menu_items, { 'parent': page_id })
        };
        return items;
      }
      else {
        var item = get_item(page_id);
        var items = {
          'type' : 'same-level',
          'top' : get_parent(page_id),
          'current' : get_item(page_id),
          'items' : _.filter(menu_items, { 'parent': item.parent })
        };
        return items;
      }
      return false;
    }



    /**
     * Has given menu item a parent true/false
     */

    function has_parent(page_id) {
      var current = _.filter(menu_items, { 'id': page_id });
      if(current.length) {
        if(current[0].parent == 0) {
          return false;
        }
      }
      return true;
    }

    /**
     * Has given menu item children true/false
     */

    function has_children(page_id) {
      var children = _.filter(menu_items, { 'parent': page_id });
      if(children.length) {
        return true;
      }
      return false;
    }


    /**
     * Get item by id
     */

    function get_item(page_id) {
      var current = _.filter(menu_items, { 'id': page_id });
      if(current.length) {
        return current[0];
      }
      var base = _.filter(menu_items, { 'parent': 0 });
      if(base.length) {
        return base[0];
      }
      return false; // item not found for ID
    }

    /**
     * Get parent by id
     */

    function get_parent(page_id) {
      if(has_parent(page_id)) {
        var item = get_item(page_id);
        var parent = _.filter(menu_items, { 'id': item.parent });
        if(parent.length) {
          return parent[0];
        }
      }
      return false; // item not found for ID
    }


    /* Toggle menu (hamburger)
    ----------------------------------------------- */

    $menu_toggle.click(function() {
      if($menu_toggle.hasClass('active')) {

        $('body').removeClass('side-menu-open');

        // remove .active class from hamburger icon
        $menu_toggle.removeClass('active').attr('aria-expanded', 'false');

        // remove .active class to menu container
        $menu.removeClass('active');

        // focus out of the menu
        $menu_toggle.focus();

      } else {

        $('body').addClass('side-menu-open');

        // .active class to hamburger icon
        $menu_toggle.addClass('active').attr('aria-expanded', 'true');

        // .active class to menu container
        $menu.addClass('active');
      }
    });

  };

})(jQuery);
