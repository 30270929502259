/* ==========================================================================
  Main.js
========================================================================== */

/**
 * Vanilla
 */

// Responsive videos
fitvids();

/**
 * jQuery
 */

(function($) {

  // Mobile navigation
  $(document).ready(function(){
    $('#side-menu').aucor_side_navigation({
      menu_toggle: "#menu-toggle", // selector for toggle
      menu_items: isa_menu
    });
  });


  $(document).ready(function(){

    // Sidebar navigation functionality
    $('#primary-navigation button').click(function() {
      var $li = $(this).parents('li');
      if ($li.hasClass('hidden') && ($li.hasClass('current_page_item') || $li.hasClass('current_page_ancestor') )) {
        $li.addClass('open');
        $li.removeClass('hidden');
      } else if($li.hasClass('current_page_item') || $li.hasClass('current_page_ancestor') ) {
        $li.addClass('hidden');
        $li.removeClass('open');
      } else {
        $li.removeClass('hidden');
        $li.toggleClass('open');
      }
      //$(this).blur();
    });

  // Add external icon to external link in entry-content
  var comp = new RegExp(location.host);
  $('.entry-content').find('a').each(function(){
    if(!comp.test($(this).attr('href')) && $(this).attr('href').indexOf('/') !== 0 && $(this).attr('href').indexOf('#') !== 0 && !$(this).hasClass('gform_anchor') && $(this).attr('href') !== '#' && !$(this).hasClass('no-external-icon')){
       var existing_svg = $(this).find('.icon-wrap');
       var image_inside = $(this).find('img');
       if(!existing_svg.length) {
        $(this).attr('target', '_blank');
        $(this).attr('rel', 'noopener noreferrer');
        if(!image_inside.length) {
          $(this).append($('<span class="icon-wrap external-icon-wrap"><svg class="icon icon-external-link" aria-hidden="true" role="img"><use xlink:href="/wp-content/themes/isa/dist/sprite/sprite.svg#icon-external-link"></use></svg></span>'));
        }
       }

    }
  });



  });

  // Hae yrityksia
  $(document).ready(function(){
    if($('#isa-company-search').length) {
      $('#isa-company-search').isa_hae();
    }
  });

})(jQuery);



